import { gql } from '@apollo/client';
export const upsertOnboardingIntent = gql `
  mutation upsertOnboardingIntent($input: OnboardingIntentInput!) {
    commands {
      upsertOnboardingIntent(input: $input) {
        status
        aggregateId
        errors {
          message
          details
        }
      }
    }
  }
`;
