import { gql } from '@apollo/client';
export const requestSupportCall = gql `
  mutation requestSupportCall(
    $method: ContactMethod
    $preferredTime: ContactPreferredTime
    $type: RequestType
  ) {
    commands {
      requestCall(method: $method, preferredTime: $preferredTime, type: $type) {
        status
        aggregateId
        errors {
          message
          details
        }
      }
    }
  }
`;
