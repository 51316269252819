import { gql } from '@apollo/client';
export const unlinkSocialAccount = gql `
  mutation UnlinkSocialAccount($connectionType: ConnectionType!) {
    commands {
      unlinkSocialAccount(connectionType: $connectionType) {
        aggregateId
        status
        errors {
          message
          details
        }
      }
    }
  }
`;
