import { __awaiter } from "tslib";
import { ContentfulContentType, ScreenComponentData, ScreenData } from '@core-lib/features';
import { LOG } from '@core-lib/log';
import { getEnv } from '@misha-lib/config/envs';
import { createClient } from 'contentful';
import { GraphQLClient, gql } from 'graphql-request';
import { DateTime } from 'luxon';
import { toJS } from 'mobx';
import dataEn from '../../../../contentful/en-US/en-US-screen.json';
import dataEs from '../../../../contentful/es-US/es-US-screen.json';
import { contentfulRestPreviewClient } from './contenfulClient';
const contentfulConfig = getEnv().contentfulConfig;
const graphQLClient = new GraphQLClient(`https://graphql.contentful.com/content/v1/spaces/${contentfulConfig.space}/environments/${contentfulConfig.environment}`, {
    headers: {
        authorization: `Bearer ${contentfulConfig.accessToken}`
    }
});
const client = createClient(contentfulConfig);
function mapContentfulEntries(items) {
    return items.items.map(item => item.fields);
}
export default class ContentfulActions {
    constructor(sdk) {
        Object.defineProperty(this, "sdk", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: sdk
        });
        Object.defineProperty(this, "getEmsPhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ state, county }) => {
                var _a, _b;
                return ((_b = (_a = this.store.emsRecords.find(e => e.county.toLowerCase().replace('county', '').trim() ===
                    county.replace('county', '').trim() && e.state.toLowerCase().trim() === state)) === null || _a === void 0 ? void 0 : _a.emsPhoneNumber) !== null && _b !== void 0 ? _b : '911');
            }
        });
    }
    get store() {
        return this.sdk.contentful.S;
    }
    fetchContentType(contentType, locale) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield client
                .getEntries({
                content_type: contentType,
                locale
            })
                .then((entries) => {
                return entries.items;
            });
        });
    }
    fetchPreviewByID(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield contentfulRestPreviewClient.getEntry(id).then((entry) => {
                return entry;
            });
        });
    }
    loadScreenData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setScreenDataLoading(true);
                LOG.info('ContentfulActions: loadScreenData');
                const startDTS = DateTime.now();
                yield Promise.all([
                    this.loadScreenDataByLocale('en-US'),
                    this.loadScreenDataByLocale('es-US')
                ]);
                if (this.store.screenData.has('en-US')) {
                    const diff = startDTS.diffNow('seconds', { conversionAccuracy: 'casual' });
                    LOG.info(`ContentfulActions: loadScreenData loaded in [${Math.abs(diff.seconds)}s]`);
                }
                else {
                    LOG.error('ContentfulActions: unable to find english localization!', this.store.screenData);
                }
            }
            catch (e) {
                LOG.error('ContentfulActions: loadScreenData', e);
                try {
                    yield this.loadScreenDataByLocaleFromFile('en-US');
                    yield this.loadScreenDataByLocaleFromFile('es-US');
                }
                catch (error) {
                    LOG.error('ContentfulActions: ', error);
                }
            }
            finally {
                this.store.setScreenDataLoading(false);
            }
        });
    }
    getScreenDataByLocale(entries, locale) {
        LOG.info('ContentfulActions: getScreenDataByLocale', locale);
        try {
            const results = entries.map((e) => {
                const screenComponentsRes = [];
                e.fields.screenComponent.forEach(c => {
                    var _a;
                    const sc = new ScreenComponentData(c.fields.screenComponentId, c.fields.type, (_a = c.fields.textComponent) !== null && _a !== void 0 ? _a : '', c.fields.richTextComponent ? c.fields.richTextComponent : null, c.fields.imageComponent ? 'https:' + c.fields.imageComponent.fields.file.url : '');
                    screenComponentsRes.push(sc);
                });
                return new ScreenData(e.fields.screenId, screenComponentsRes);
            });
            LOG.info(`ContentfulActions: getScreenDataByLocale loaded ${results.length} screen records for ${locale} locale`);
            return results;
        }
        catch (ex) {
            LOG.error('ContentfulActions: getScreenDataByLocale', ex);
        }
    }
    loadScreenDataByLocale(locale) {
        return __awaiter(this, void 0, void 0, function* () {
            LOG.info('ContentfulActions: loadScreenDataByLocale', locale);
            try {
                const entries = yield this.fetchContentType(ContentfulContentType.ScreenData, locale);
                if (entries.error) {
                    throw new Error(`${entries.error}`);
                }
                const results = this.getScreenDataByLocale(entries, locale);
                results && this.store.setScreenData(locale, results);
            }
            catch (ex) {
                LOG.error('ContentfulActions: loadScreenDataByLocale', ex);
                yield this.loadScreenDataByLocaleFromFile('en-US');
            }
        });
    }
    loadScreenDataByLocaleFromFile(locale) {
        return __awaiter(this, void 0, void 0, function* () {
            LOG.info('ContentfulActions: loadScreenDataByLocaleFromFile', locale);
            const data = locale === 'en-US' ? dataEn : dataEs;
            const entries = data.items.map(e => {
                const screenComponent = e.fields.screenComponent.map(a => {
                    const entry = data.includes.Entry.find(k => {
                        return k.sys.id === a.sys.id;
                    });
                    const asset = (entry === null || entry === void 0 ? void 0 : entry.fields.imageComponent) &&
                        data.includes.Asset.find(k => {
                            var _a;
                            return k.sys.id === ((_a = entry === null || entry === void 0 ? void 0 : entry.fields.imageComponent) === null || _a === void 0 ? void 0 : _a.sys.id);
                        });
                    return Object.assign(Object.assign({}, ((entry === null || entry === void 0 ? void 0 : entry.fields.imageComponent) && {
                        fields: Object.assign(Object.assign({}, entry === null || entry === void 0 ? void 0 : entry.fields), { imageComponent: Object.assign(Object.assign({}, entry.fields.imageComponent), { fields: { file: asset === null || asset === void 0 ? void 0 : asset.fields.file } }) }),
                        metadata: entry === null || entry === void 0 ? void 0 : entry.metadata,
                        sys: entry === null || entry === void 0 ? void 0 : entry.sys
                    })), (!(entry === null || entry === void 0 ? void 0 : entry.fields.imageComponent) && {
                        fields: entry === null || entry === void 0 ? void 0 : entry.fields,
                        metadata: entry === null || entry === void 0 ? void 0 : entry.metadata,
                        sys: entry === null || entry === void 0 ? void 0 : entry.sys
                    }));
                });
                return {
                    fields: {
                        screenComponent,
                        screenId: e.fields.screenId
                    },
                    metadata: e.metadata,
                    sys: e.sys
                };
            });
            try {
                const results = this.getScreenDataByLocale(entries, locale);
                results && this.store.setScreenData(locale, results);
            }
            catch (ex) {
                LOG.error('ContentfulActions: loadScreenDataByLocaleFromFile', ex);
            }
        });
    }
    getEntry(id) {
        return __awaiter(this, void 0, void 0, function* () {
            client
                .getEntry(id)
                .then((entry) => console.log('Entry: ', entry))
                .catch(console.error);
        });
    }
    loadEmsRecords() {
        return __awaiter(this, void 0, void 0, function* () {
            let emsRecords = [];
            try {
                const entries = yield this.fetchContentType(ContentfulContentType.EMSRecord);
                emsRecords = entries.map((e) => {
                    return Object.assign({}, e.fields);
                });
            }
            catch (error) {
                LOG.info('ContentfulActions:', error);
            }
            LOG.info('ContentfulActions: emsRecords', emsRecords);
            this.store.setEmsRecords(emsRecords);
            return emsRecords;
        });
    }
    loadClinicianBioContent() {
        return __awaiter(this, void 0, void 0, function* () {
            const entries = yield this.fetchContentType(ContentfulContentType.ClinicianBio);
            const result = entries.map((e) => {
                const { clinicianName, headshot, jobTitle, certificationsTitle, pronouns, npiNumber, overallRating, providerCognitoUuid } = e.fields;
                return {
                    clinicianName,
                    headshotUri: headshot === null || headshot === void 0 ? void 0 : headshot.fields.file.url,
                    jobTitle,
                    certificationsTitle,
                    pronouns,
                    npiNumber,
                    overallRating,
                    uuid: providerCognitoUuid
                };
            });
            LOG.info('ContentfulActions: CONTENTFUL CLINICIAN BIO', result);
            this.store.setClinicianBios(result);
            return result;
        });
    }
    getEntries() {
        return __awaiter(this, void 0, void 0, function* () {
            return client
                .getEntries()
                .then((entries) => {
                this.store.setEntries(entries.items);
                return entries.items;
            })
                .catch(console.error);
        });
    }
    loadChatbotInteractions() {
        return __awaiter(this, void 0, void 0, function* () {
            const query = gql `
      query {
        chatbotFlowCollection(limit: 1) {
          items {
            interactionsCollection(limit: 10) {
              items {
                interactionName
                childrenCollection(limit: 10) {
                  items {
                    childName
                    messagesCollection(limit: 10) {
                      items {
                        interactionName
                        sys {
                          id
                        }
                      }
                    }
                    sys {
                      id
                    }
                  }
                }
                sys {
                  id
                }
              }
            }
          }
        }
      }
    `;
            const interaction = yield graphQLClient.request(query);
            let interactions = interaction.chatbotFlowCollection.items[0].interactionsCollection.items;
            const interactionEntries = interactions.reduce((acc, cv) => {
                acc.push({
                    name: cv.interactionName,
                    id: cv.sys.id
                });
                if (cv.childrenCollection && cv.childrenCollection.items.length > 0) {
                    for (const child of cv.childrenCollection.items) {
                        acc.push({
                            name: child.childName,
                            id: child.sys.id
                        });
                        if (child.messagesCollection && child.messagesCollection.items.length > 0) {
                            for (const messageItem of child.messagesCollection.items) {
                                acc.push({
                                    name: messageItem.interactionName,
                                    id: messageItem.sys.id
                                });
                            }
                        }
                    }
                }
                return acc;
            }, []);
            this.store.setChatbotInteractions(interactionEntries);
        });
    }
    getExercices() {
        return __awaiter(this, void 0, void 0, function* () {
            const query = gql `
      query {
        carePlanExerciseCollection(limit: 350) {
          items {
            exerciseTitle
            exerciseDescription {
              json
            }
            isTwoSided
            exerciseThumbnail {
              url
            }
            defaultPortraitIntroAsset {
              introVideoName
              introVideoAsset {
                url
              }
              lengthInSeconds
            }
            defaultPortraitLoopAsset {
              loopVideoName
              loopVideoAsset {
                url
              }
              durationInSeconds
            }
            defaultLandscapeIntroAsset {
              introVideoName
              introVideoAsset {
                url
              }
              lengthInSeconds
            }
            defaultLandscapeLoopAsset {
              loopVideoName
              loopVideoAsset {
                url
              }
              durationInSeconds
            }

            rightPortraitIntroAsset {
              introVideoName
              introVideoAsset {
                url
              }
              lengthInSeconds
            }
            rightPortraitLoopAsset {
              loopVideoName
              loopVideoAsset {
                url
              }
              durationInSeconds
            }
            rightLandscapeIntroAsset {
              introVideoName
              introVideoAsset {
                url
              }
              lengthInSeconds
            }
            rightLandscapeLoopAsset {
              loopVideoName
              loopVideoAsset {
                url
              }
              durationInSeconds
            }
            carePathTagsCollection(limit: 10) {
              items {
                tag
              }
            }
            sys {
              id
            }
          }
        }
      }
    `;
            const data = yield graphQLClient.request(query);
            let items = data.carePlanExerciseCollection.items;
            const mappedItems = items.map(x => (Object.assign(Object.assign({}, x), { id: x.sys.id, title: x.exerciseTitle, description: x.exerciseDescription, url: '', thumbnail: x.exerciseThumbnail.url, isTwoSided: x.isTwoSided, tags: x.carePathTagsCollection.items.map(x => x.tag) })));
            return mappedItems;
        });
    }
    getSelectInputs() {
        return __awaiter(this, void 0, void 0, function* () {
            const entries = yield this.fetchContentType(ContentfulContentType.SelectInput);
            return entries;
        });
    }
    loadSelectInputs() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setLoading(true);
                const entries = yield this.fetchContentType(ContentfulContentType.SelectInput);
                if (entries.error) {
                    throw new Error(`${entries.error}`);
                }
                const results = entries.map((e) => {
                    return Object.assign({}, e.fields);
                });
                this.store.setSelectInputs(results);
                return results;
            }
            catch (e) {
                LOG.error('ContentfulActions: error loadSelectInputs', e);
                this.store.setError(true);
            }
            finally {
                this.store.setLoading(false);
            }
        });
    }
    loadSignupServiceTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            LOG.info('=== CONTENTFUL SIGNUP SERVICE TYPES');
            const categories = yield client.getEntries({
                content_type: ContentfulContentType.ServiceCategory
            });
            const packages = yield client.getEntries({
                content_type: ContentfulContentType.ServicePackage
            });
            const specialists = yield client.getEntries({
                content_type: ContentfulContentType.ServiceSpecialist
            });
            this.store.serviceCategories = mapContentfulEntries(categories);
            this.store.servicePackages = mapContentfulEntries(packages);
            this.store.serviceSpecialists = mapContentfulEntries(specialists);
        });
    }
    getCarePlanTaskTemplates() {
        return __awaiter(this, void 0, void 0, function* () {
            const tasksResponse = yield graphQLClient.request(gql `
      query {
        carePlanTaskCollection {
          items {
            category
            title
            description
            instructionsMarkdown
            imageLarge {
              url
            }
            sys {
              id
            }
          }
        }
      }
    `);
            const tasks = tasksResponse.carePlanTaskCollection.items.map((item) => {
                item.id = item.sys.id;
                return item;
            });
            return tasks.sort((a, b) => a.title.localeCompare(b.title));
        });
    }
    loadAppointmentTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setLoading(true);
                const entries = yield this.fetchContentType(ContentfulContentType.AppointmentType);
                LOG.info('ContentfulActions: AppointmentTypes list', toJS(entries));
                if (entries.error) {
                    throw new Error(`${entries.error}`);
                }
                const results = entries.map((e) => {
                    return Object.assign({}, e.fields);
                });
                this.store.setAppointmentTypes(results);
                return results;
            }
            catch (e) {
                LOG.error('ContentfulActions: Could not load AppointmentTypes', e);
                this.store.setError(true);
            }
            finally {
                this.store.setLoading(false);
            }
        });
    }
    loadProviderTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.store.setLoading(true);
                const entries = yield this.fetchContentType(ContentfulContentType.ProviderType);
                if (entries.errors) {
                    throw new Error(`${entries.errors}`);
                }
                const results = entries.map((e) => {
                    return Object.assign({}, e.fields);
                });
                LOG.info('ContentfulActions: loadProviderTypes results', results);
                this.store.setProviderTypes(results);
                return results;
            }
            catch (e) {
                LOG.error('ContentfulActions: Could not load loadProviderTypes', e);
                this.store.setError(true);
            }
            finally {
                this.store.setLoading(false);
            }
        });
    }
}
